// Styling for Filters
.filters {
  display: grid;
  row-gap: 0.6rem;
  align-content: flex-start;
  width: 100%;
  padding: 3rem 0.5rem 3rem 0.5rem;

  div.ant-collapse-content {
    border: none;
  }

  &__panel {
    background-color: var(--color-blue-light);
    border-radius: var(--border-radius-small) !important;
    color: var(--color-font) !important;

    span {
      color: var(--color-grey) !important;
      font-size: large;
    }
  }

  &__collapse {
    transition: box-shadow 0.3s !important;
    cursor: pointer;
    border: none !important;
  }

  &--drawer {
    width: 30rem;
    padding: 9rem 3rem 3rem 3rem;

    @media only screen and (max-width: 500px) {
      width: 50vw;
      padding: 9rem 3rem 3rem 3rem;
    }
  }

  &--calendar {
    width: 16rem;
    padding: 3rem 1rem 3rem 0;
  }

  &__button {
    background-color: var(--color-blue-light);
    width: 80%;
    border: none;
    color: black;
    padding: 8px 8px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 1.8rem;
    margin: 4px 2px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: var(--box-shadow-subtle);
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: var(--box-shadow);
      z-index: 2;
    }
  }

  &__text {
    margin-top: 20px;
    margin-left: 25px;
    font-size: 2.5rem;
    text-align: left;
  }

  &__title {
    padding: 1rem 0 0rem;
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--color-font) !important;
  }

  &__group {
    display: grid;
    align-content: flex-start;
    row-gap: 0.6rem;
    overflow: hidden;

    &:nth-child(3n + 1) {
      & .filter__svg-border {
        stroke: var(--color-filter--1);
      }
      & .filter__svg--filled .filter__svg-fill {
        fill: var(--color-filter--1);
      }
    }

    &:nth-child(3n + 2) {
      & .filter__svg-border {
        stroke: var(--color-filter--2);
      }
      & .filter__svg--filled .filter__svg-fill {
        fill: var(--color-filter--2);
      }
    }

    &:nth-child(3n) {
      & .filter__svg-border {
        stroke: var(--color-filter--3);
      }
      & .filter__svg--filled .filter__svg-fill {
        fill: var(--color-filter--3);
      }
    }
  }
}

.filter {
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-content: center;
  column-gap: 1rem;
  

  &__svg {
    height: 22px;
    width: 22px;
    &-border {
      stroke-width: 3;
    }

    &-fill {
      stroke-width: 1;
    }
  }

  &__label {
    font-weight: 500;
    color: var(--color-font) !important;
    font-size: 1.3rem;
    grid-column: 2 / 3;
    display: inline-block;
    align-self: center;
    text-transform: capitalize;
  }
}

.rotate {
    transform: rotate(45deg);
}

.icon {
  margin-top: 2px;
  float: right;
}