.navbar {
  display: grid;
  grid-template-columns: max-content 1fr repeat(3, max-content);
  align-items: center;
  padding: 1em 1.8em;
  column-gap: 1.8em;
  font-size: 1rem; // Since Navbar using em then this sets relative size

  .tiny700 {
    width: 162px;
    height: 30px;
  }

  &__logo {
    &-container {
      display: flex;
      align-items: center;
    }

    &-name {
      height: 9em;
    }

    &-union {
      height: 3.4em;
      width: 3.7em;
      border-left: 2px solid var(--color-grey-darker);
      padding-left: 0.6em;
      margin-left: 0.6em;
    }
  }

  &__links {
    display: grid;
    justify-self: center;
    grid-template-columns: repeat(4, max-content);
    column-gap: 3em;
  }

  &__link {
    color: var(--color-grey-darker);
    text-align: center;
    transition: all 0.3s;
    font-weight: 500;
    font-size: 1.6em;
    transition: all 0.3s;

    &:hover {
      color: var(--color-black);
    }

    &--active {
      color: var(--color-black);
    }
  }

  &__menu-icon {
    background-color: transparent;
    border: none;
    display: none;
    cursor: pointer;

    & svg {
      width: 2.4em;
      height: 2.4em;
    }
  }

  &__profile {
    border: none;
    // padding: 0.4rem 0.4rem;

    & img {
      cursor: pointer;
      height: 3.4rem;
    }
  }

  &__emergency {
    font-size: 1.4rem;
  }

  &__drawer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, max-content) 1fr max-content;
    row-gap: 0.2em;
    padding-top: 6em;
    width: 28em;
    height: 100%;

    &-name,
    &-email {
      margin: 0em 2rem;
    }

    &-name {
      font-size: 1.6em;
      font-weight: bold;
      color: var(--color-font) !important;
    }

    &-email {
      padding-bottom: 2em;
    }

    &-link {
      font-size: 1.6em;
      padding: 0.4em 2rem;
      color: var(--color-font) !important;
      border-radius: var(--border-radius-tiny);
      transition: all 0.3s;
      background-color: white;
      border: none;
      text-align: flex-start;
      cursor: pointer;

      &:nth-child(7) {
        margin: 1.6em 0.8rem;
      }

      &:hover,
      &--active {
        background-color: var(--color-blue-light);
        color: var(--color-black);
      }

      &--active {
        font-weight: bold;
      }
    }

    &-logout {
      grid-row: 9 / 10;
      font-size: 1.6em;
      margin: 2em 3em;
      padding: 0.4em;
      border: none;
      border-radius: var(--border-radius-tiny);
      background-color: var(--color-blue-light);
      box-shadow: var(--box-shadow-subtle);
      cursor: pointer;
    }
  }
}

.nav-menu {
  & .MuiPaper-root {
    top: 60px !important;

    & .MuiButtonBase-root {
      font-size: 1.2rem !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .navbar {
    font-size: 1.2rem;
    column-gap: 2em;
    grid-template-columns: max-content 1fr max-content max-content;
    justify-items: center;

    &__drawer {
      font-size: 1.6rem;
    }

    &__links,
    &__profile {
      display: none;
    }

    &__menu-icon {
      display: grid;
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    font-size: 1.2rem;

    &__menu-icon {
      & svg {
        width: 2.8em;
        height: 2.8em;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .navbar {
    font-size: 1rem;
    column-gap: 1.2em;

    &__drawer {
      width: 70vw;
    }

    &__logo {
      &-name {
        height: 2em;
      }

      &-union {
        height: 2.8em;
        width: 3.4em;
      }
    }
  }
}


