.staff-account-banner {

    padding-top: 5px;
    padding-left: 5px;
    color: white;
    text-align: left;

    .debugger-name {
        display: inline;
        margin-bottom: 5px;
        float: right;

        p {
            padding-right: 10px;
            display: inline;
        }

        input {
            padding-left: 5px;
            background: black;
            border: none;
            background: grey;
            opacity: .6;
            outline: none;
            margin-right: 30px;
        }
    }
}