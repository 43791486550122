Button.deselect-all {
  width: 100%;
}

Button.deselect-all-false {
  border: 1px solid var(--color-blue-royal);
}

Button.deselect-all-true {
  background: orange;
  color: orange;
  opacity: 60%;
  border: none;
}