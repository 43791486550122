.home {
  background-color: var(--color-blue-light);
  display: grid;
  padding: 2rem;
  row-gap: 2rem;
  column-gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, max-content);

  & > div {
    background: var(--color-white);
    border-radius: var(--border-radius-large);
  }

  @media only screen and (min-width: 1200px) {
    padding: 2rem 12vw;
  }

  &__title {
    text-align: center;
  }
}

.home-events {
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  padding: 2rem 0 3rem;
  display: grid;
  align-content: flex-start;
  justify-content: center;
  grid-template-columns: 6rem 1fr 6rem;
  grid-template-rows: max-content 1fr;

  &__none-title {
    text-align: center;
    grid-column: 1 / -1;
  }

  &__title {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    width: max-content;
    justify-self: center;
  }

  &__list {
    display: grid;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;

    @media only screen and (min-width: 950px) {
      align-items: center; // This is a quick fix should be changed
    }
  }

  &__icon {
    &--next,
    &--prev {
      width: 10rem; // height is width as 90deg rotate
      height: 6rem;
      cursor: pointer;
      align-self: center;
      fill: var(--color-grey-dark);
    }

    &--prev {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
      transform: rotate(-90deg) scaleX(1.2) translateY(-2rem);
      justify-self: flex-start;
    }

    &--next {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
      transform: rotate(90deg) scaleX(1.2) translateY(-2rem);
      justify-self: end;
    }

    &--hidden {
      visibility: hidden;
      pointer-events: none;
    }
  }

  @media only screen and (max-width: 700px) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }
}

.home-events,
.home-links {
  row-gap: 1.2rem;
}

.home-event {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-subtle);
  border-radius: var(--border-radius-medium);
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  background-color: var(--color-blue-light);
  padding: 0.6rem 1.6rem;
  transition: box-shadow 0.3s;
  overflow-y: hidden; // This should be temporary!!

  &__details {
    padding-top: 0.6rem;
    align-self: end;
  }

  &__location {
    font-size: 1.2rem;
    color: #424242;
  }

  &:hover {
    box-shadow: var(--box-shadow);
  }

  @media only screen and (max-width: 950px) {
    font-size: 1.4rem;
  }

  @media only screen and (min-width: 950px) {
    height: 160px; // This is a quick fix should be changed
  }

  @media only screen and (min-width: 1200px) {
    height: 180px; // This is a quick fix should be changed
  }

  @media only screen and (max-width: 700px) {
    min-height: 100%;
    height: max-content;
  }

  &:hover {
    cursor: pointer;
  }
}

.home-links {
  grid-row: 1 / 2;
  grid-column: 5 / -1;
  padding: 2.4rem;
  display: grid;
  align-content: start;
  max-height: 300px;
  overflow: scroll;

  @media only screen and (max-width: 700px) {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    grid-template-columns: minmax(5rem, 1fr) minmax(max-content, 70%) minmax(5rem, 1fr);

    & > * {
      grid-column: 2 / 3;
    }
  }
}

.home-link {
  text-align: center;
  background-color: var(--color-blue-light);
  padding: 0.8rem 1rem;
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-subtle);
  transition: box-shadow 0.3s;

  &:active,
  &:focus,
  &:hover {
    box-shadow: var(--box-shadow);
    color: var(--color-font) !important;
  }
}

.home-calendar {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  padding: 2rem 4rem;

  @media only screen and (max-width: 700px) {
    grid-row: 3 / 4;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem 1rem;
  }
}
