.video-invite {
    position: fixed;
    bottom: -10px;
    right: 0px;
    padding: 13px 30px 20px;
    margin-right: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    // width: 100%;
    z-index: 9999;
    color:  var( --color-blue-buttons);
    background: var( --color-blue-solitude);
    border: 1px dashed var(--color-blue-buttons);

    &:hover {
        border: 1px dashed var( --color-blue-solitude);
        color: var( --color-blue-solitude);
        background: var(--color-blue-buttons);
    }
}

@media screen and (max-width: 500px) {

    .video-invite {
        width: 100%;
        margin-right: 0px;
        font-size: 16px;
    }
}