.text-primary {
  &,
  &--bold {
    font-size: 2.2rem;
  }
}

.text-secondary {
  &,
  &--bold {
    font-size: 1.6rem;
  }
}


.text-tertiary {
  &,
  &--bold {
    font-size: 1.5rem;
  }
}

.text-primary,
.text-secondary,
.text-tertiary {
  &--bold {
    font-weight: 700;
    // color: var(--color-font) !important;
  }

  font-weight: 500;
  color: var(--color-font) !important;
}

.text-banner {
  text-align: center;
  color: var(--color-font);
  font-style: italic;
  font-size: 1.6rem;
}

.text-footer {
  color: var(--color-font);
  font-style: italic;
  font-size: 1.5rem;
}
