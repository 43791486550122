.list-page {
  display: grid;
  grid-template-columns: max-content 1fr;
  // padding: 2rem 0 8rem;

  &__filters {
    width: 20rem;
  }

  &--filters {
    padding: 2rem 6rem 8rem;
  }

  &__content {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    row-gap: 0.4rem;
    grid-template-columns: 1fr min-content 1fr;
  }

  &__search-bar-box {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 25px;
    border: 2px solid black;
  }

  &__search-bar {
    grid-column: 1 / -1;
    text-align: center;
    width: 100%;
    height: 30px;
    border-radius: 25px;
  }

  &__filter-button {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    display: none;
    justify-self: flex-start;
    margin-left: 2rem;
    align-self: center;
  }

  &__title,
  &__heading {
    grid-column: 1 / -1;
    text-align: center;
    width: 100%;
  }

  &__title {
    grid-row: 1 / 2;
    @extend .heading-secondary;
  }

  &__heading {
    @extend .text-secondary;
  }

  &__list {
    grid-column: 2 / 3;
    display: grid;
    align-content: start;
    row-gap: 2rem;
    padding-top: 2rem;
  }

  &__loading {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 10rem;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .list-page {
    &--filters {
      padding: 2rem 3rem 8rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .list-page {
    &--filters {
      padding: 2rem 0 8rem; // remove extra side padding
    }

    &__content {
      grid-template-columns: 5rem 1fr 5rem;
    }

    &__filters {
      display: none;
    }

    &__filter-button {
      display: grid;
    }
  }
}

@media only screen and (max-width: 600px) {
  .list-page {
    &__content {
      grid-template-columns: 3rem 1fr 3rem;
    }
  }
}
