.TOS-page {
    padding: 30px 120px 20px;

    h1 {
        text-align: center;
    }

    .acceptance-indication {
        margin-top: 15px;
    }

    .TOS-mobile {
        display: none;
    }

    embed {
        height:500px;
    }
}

.welcome-page {
    padding: 3% 20%;
    font-size: 22px;

    a {
        color: var(--color-blue-eastern);
    }

    h1 {
        margin-bottom: 10px;
        text-align: center;
    }

    h2 {
        font-size: 20px;
    }

    p, span {
        font-size: 16px;
    }

    .welcome-section {
        display: grid;
        grid-template-columns: 1fr 10fr;
        text-align: left;
        margin: 25px 0px;

        h2 {
            display: inline;
            margin-bottom: 0px;
        }

        .welcome-section-emoji {
            margin-top: 10px;

            span {
                font-size: 30px;
                border-radius: 50px;
                padding: 25px 20px 15px 20px;
                text-align: center;
                margin-right: 20px;
                background: var(--color-blue-light);
                box-shadow: var(--box-shadow-subtle);
            }
        }

    }
}

.welcome-contact {
    padding: 10px 10px 5px 0px;
    margin-bottom: 20px;

    a {
        color: black;
    }

    .welcome-icon-set {
        padding: 10px 10px 5px 5px;
        margin-left: 5px;
        box-shadow: var(--box-shadow-subtle);
        border-radius: 50px;
        white-space: nowrap;
    }

    .welcome-icon {
        margin-left: 18px;
        top: 0px;

        &:hover, &:active  {
            color: var(--color-blue-eastern);
        }
    }

    .welcome-icon-farright {
        margin-right: 18px;
    }
}

.continue-button {
    margin-top: 20px;
    padding: 30px;
    color: black;
    width: 100%;
    text-transform: none;
    border: none;
    background-color: var(--color-blue-eastern);
    border-radius: var(--border-radius-medium);
    color: var(--color-white);
    padding: 1rem 2rem;
    cursor: pointer;
    box-shadow: var(--box-shadow-subtle);
    transition: box-shadow 0.3s;

    &:hover {
        background: lightgrey;
    }

    h2 {
        font-size: 26px;
        color: white;
        padding : 5px;
        margin: 0px;
    }
}

.acceptance-indication {
    font-size: 20px;
    padding-left: 20px;


    .checkbox {
        width: 20px;
        height: 20px;
    }

    label {
        display: inline;
        font-size: 25px;
        margin-bottom: 50px;
        padding-bottom: 30px;
    }

}

@media only screen and (max-width: 1000px) {
    .welcome-page {
        padding: 100px;
    }

    .TOS-page {
        padding: 100px 20px 20px;

        .TOS-mobile {
            display: block;

            h1, p {
                text-align: center;
                padding-bottom: 40px;
            }

            p {
                font-size: 20px;
            }

            a {
                padding-top: 200px;
            }
        }

        embed, .TOS-desktop {
            display: none
        }
    }
}

@media only screen and (max-width: 580px) {

    .welcome-page {
        padding: 20px;

        h1 {
            font-size: 30px;
        }
    }

    .continue-button {
        
        h2 {
            font-size: 16px;
        }

        margin-bottom: 40px;
    }
}