.mental-health {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    padding: 4rem 12rem;

    @media only screen and (max-width: 1000px) {
      padding-left: 8rem;
      padding-right: 8rem;
    }

    @media only screen and (max-width: 800px) {
      padding: 4rem;
    }
  }

  .recommended-resources-options {
    button {
        margin: 5px;
    }

    // .inactive-button {
    //     pointer-events: none;

    //     button {
    //         background: grey;
    //         color: light-grey;
    //     }
    // }
  }

}

.tab-container {
  display: grid;
  justify-items: center;
  row-gap: 4rem;
  padding: 4rem 0;
  background-size: cover;
  width: 100%;
}

.tab {
  &__info {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
    align-items: center;
    padding: 0 10vw;
    column-gap: 4rem;
    row-gap: 1rem;
    width: 100%;
  }

  &__img {
    grid-row: 1 / -1;
    grid-column: 1 / 2;
    width: 10rem;
    height: 9rem;

    @media only screen and (max-width: 600px) {
      width: 8rem;
      height: 7.2rem;
    }
  }

  &__description {
    font-size: 1.6rem;
    color: var(--color-grey);
  }

  &__button {
    text-transform: none;
    border: none;
    background-color: var(--color-blue-eastern);
    border-radius: var(--border-radius-medium);
    font-size: 1.6rem;
    color: var(--color-white);
    padding: 1rem 2rem;
    cursor: pointer;
    box-shadow: var(--box-shadow-subtle);
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: var(--box-shadow);
    }
  }
}

.recommendation-hits-container {
    font-size: 9px;
    white-space: break-spaces;
    line-height: 30px;
    display: inline-block;
    margin-top: 4%;

    .hits-explanation {
        font-size: 11px;
        margin-bottom: 10px;
        line-height: 15px;
        color: var(--color-grey-light)
    }

    .hit-instance {
        display: inline;
        font-size: 11px;
        box-shadow: var(--box-shadow-subtle);
        // border: 2px solid var(--box-shadow);
        color: var(--color-grey);
        padding: 0px 6px;
        margin: 0px 10px 4px 0px;
        border-radius: 5px;
        white-space: nowrap;
        display: inline-block;
        // border: 1px solid grey;
    }

    @media only screen and (max-width: 600px) {
        .hit-instance{
            padding: 0px 4px;
            margin: 0px 1px 0px 0px;
            font-size: 9px;
            line-height: 20px;
        }
    }
}


.recommendations__title-block {

    width: 80rem;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 6fr;

    a {
        padding: 0px;
        margin: 0px;
    }

    .recommendations__retake, .recommendations__title {
        display: block;
        text-align: center;
    }

    .recommendations__retake {
    }

    .recommendations__title {
        margin: 10px;
        padding: 14px;
        font-size: 22px;
        text-align: left;
    }

    button {
        margin: 10px;
        margin-top: 20px;
        padding: 30px;
        color: black;
        text-transform: none;
        border: none;
        background-color: var(--color-blue-eastern);
        border-radius: var(--border-radius-medium);
        font-size: 2.4rem;
        color: var(--color-white);
        padding: 1rem 2rem;
        cursor: pointer;
        box-shadow: var(--box-shadow-subtle);
        transition: box-shadow 0.3s;
    }
}