.inline_icon {
    width: 30px;
    padding-top: 9px;
}

.hit-instance.hierarchy-score-3 {
    background: rgba(35, 217, 108, 0.3);
}

.hit-instance.hierarchy-score-2 {
    background: rgba(255, 188, 44, 0.3);
}

.hit-instance.hierarchy-score-1 {
    background: rgba(93, 35, 217, 0.3);
}