.onboarding {
	display: grid;
	grid-template-columns: 1fr minmax(max-content, 60%);
	height: 100%;
	justify-content: center;
	position: relative;
	font-size: 1rem;

	@media only screen and (max-width: 800px) {
		font-size: 1.2rem;
		grid-template-columns: 1fr minmax(max-content, 50%);
	}

	@media only screen and (max-width: 600px) {
		font-size: 1.4rem;
		grid-template-columns: 1fr;
		grid-template-rows: 6rem 1fr;
	}

	&__onboarding-copy {
		margin: 5rem;
		text-align: justify;
		color: #4E82BD;
	}

	&__lang-toggle {
		position: absolute;
		top: 2rem;
		right: 4rem;
		padding: 0.6rem;

		@media only screen and (max-width: 600px) {
			background-color: var(--color-white);
			top: 1rem;
			right: 3rem;
		}
	}

	&__logo-container {
		display: grid;
		justify-items: center;
		align-content: center;
		background-color: var(--color-blue-light);
		padding-bottom: 7%;

		& > img:nth-of-type(1) {
			display: block;
			width: 20%;
			max-width: 20em;
		}
		& > img:nth-of-type(2) {
			display: block;
			margin-top: 0.5em;
			width: 35%;
		}
		& > div {
			margin-top: 3em;
			width: 79%;
		}
		& > h1 {
			margin-top: 7px;
			font-size: 1.8em;
		}

		@media only screen and (max-width: 1025px) {
			& > h1 {
				font-size: 1.2em;
			}
		}

		@media only screen and (max-width: 600px) {
			padding-bottom: 0;

			& > img:nth-of-type(1) {
				display: none;
			}
			& > img:nth-of-type(2) {
				margin-top: 0;
				height: 2.4em;
				width: 18em;
			}
			& > div {
				display: none;
			}
			& > h1 {
				display: none;
			}
		}
	}

	&__content {
		display: grid;
		align-content: center;
		grid-template-columns: minmax(5em, 1fr) 50em minmax(5em, 1fr);
		row-gap: 2em;
		justify-items: center;
		padding: 3rem 0;

		& > * {
			grid-column: 2 / 3;
		}

		@media only screen and (max-width: 1000px) {
			grid-template-columns: minmax(5em, 1fr) 42em minmax(5em, 1fr);
		}

		@media only screen and (max-width: 800px) {
			grid-template-columns: minmax(3em, 1fr) 30em minmax(3em, 1fr);
		}

		@media only screen and (max-width: 600px) {
			grid-template-columns: minmax(2em, 1fr) 36em minmax(2em, 1fr);
		}

		@media only screen and (max-width: 400px) {
			grid-template-columns: 2em 1fr 2em;
		}
	}

	&__form {
		width: 100%;
		display: grid;
		justify-items: center;
	}

	&__title {
		color: var(--color-font) !important;
		text-align: center;
		font-size: 3em;
	}

	&__inputs {
		background: #ddeef6;
		border-radius: var(--border-radius-large);
		width: 100%;
		font-size: 1.8em;
		margin-bottom: 0.6em;
		display: grid;
		grid-template-columns: max-content 1fr;
		align-items: center;
		column-gap: 2em;
		padding: 1.8em 1em 0.8rem;

		& select {
			cursor: pointer;
		}

		@media only screen and (max-width: 800px) {
			grid-template-columns: 1fr;
		}

		@media only screen and (max-width: 400px) {
			padding: 1.4em 1em 0.8rem;
		}
	}

	&__input {
		display: inline-block;
		padding: 0.4em 1em;
		background: #ffffff;
		border-radius: var(--border-radius-large);
		border: 2px solid #babbbf;

		&:focus,
		&:hover {
			border: 2px solid #3870e0;
		}

		&--error {
			border: 2px solid red !important;
		}
	}

	// Add gap between inputs on login screen
	&__input {
		&--login {
			margin-bottom: 0.8em;
		}
	}

	&__label {
		color: var(--color-font) !important;
		@media only screen and (max-width: 600px) {
			margin-left: 0.4em;
		}
	}

	&__error {
		font-size: 0.7em;
		padding-left: 1em;
		padding-top: 0.2em;
		min-height: 2em;
		color: red;
		align-self: center;
		grid-column: 2 / 3;
		width: 100%;

		@media only screen and (max-width: 800px) {
			grid-column: 1 / -1;
		}
	}

	&__redirect button,
	&__link,
	&__forgot {
		border: none;
		background-color: transparent;
		cursor: pointer;
		color: var(--color-blue-royal);
	}

	&__redirect,
	&__forgot {
		grid-column: 1 / -1;
		justify-self: center;
	}

	&__redirect {
		color: var(--color-font) !important;
		font-size: 1.4em;
		margin-bottom: 1.6em;

		button {
			text-decoration: underline;
		}
	}

	&__forgot {
		font-size: 1.4em;
	}

	&__link {
		font-size: 1.6em;
	}

	&__success {
		display: grid;
		align-content: center;
		justify-content: center;
	}

	&__info {
		text-align: center;
		font-size: 1.6em;
	}
}
