.modal {
    background:#ecf4fc;
    // width: calc(clamp(50%, 700px, 90%));
    // height: calc(min(50%, 300px));

    margin: auto;
    padding: 50px 100px 50px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-size: 22px;
}

.options {

    button {
        padding: 12px;
        background: #0c8cb4;
        border: none;
        margin: 5px;
        border-radius: 10px;
        color: white;
        box-shadow: 1.5px 1.5px 2px rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;

        &:hover {
            box-shadow: 1.5px 1.5px 5px rgba(0, 0, 0, 0.5);
        }
    }

}