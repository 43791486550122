@font-face {
  font-family: "Avenir";
  font-weight: 700;
  font-style: normal;
  font-variant: normal;

  src: url("./fonts/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: "Avenir";
  font-weight: 500;
  font-style: normal;
  font-variant: normal;

  src: url("./fonts/AvenirLTStd-Roman.otf");
}

@font-face {
  font-family: "Avenir";
  font-weight: 500;
  font-style: normal;
  font-variant: normal;

  src: url("./fonts/AvenirLTStd-Book.otf");
}

html {
  font-size: 62.5%; /* 1rem = 16px * 62.5% = 10px */
  box-sizing: border-box;

  @media only screen and (max-width: 1200px) {
    font-size: 58%;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 52%;
  }

  @media only screen and (max-width: 800px) {
    font-size: 48%;
  }

  @media only screen and (max-width: 600px) {
    font-size: 44%;
  }
}

html,
body,
#root,
.app {
  height: 100%;
}

*:disabled {
  background-color: var(--color-grey-light) !important;
  border-color: var(--color-grey) !important;
  cursor: default;
}

body {
  margin: 0;
  font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Ubuntu", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-family: "Avenir", sans-serif;
  -moz-user-select: auto;
  -webkit-user-select: auto;
  box-sizing: inherit;
}

.list-page__loading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#confetti {
  // background: orange;
  // opacity: 50%;
  width: 100%;
  height: 100%;
  // display: none;
  position: fixed;
  z-index: 4000;
  pointer-events: none
}